@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@400;600;700&display=swap");
@import "react-awesome-slider/dist/styles.css";
@import "./variables.scss";

/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
	margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
	list-style: none;
}

/* Set core root defaults */
html:focus-within {
	scroll-behavior: smooth;
}

/* Set core body defaults */
body {
	min-height: 100vh;
	height: 100%;
	text-rendering: optimizeSpeed;
	line-height: 1.5;
	padding-bottom: 98px;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
	text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
	max-width: 100%;
	display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
	font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
	html:focus-within {
		scroll-behavior: auto;
	}

	*,
	*::before,
	*::after {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}

* {
	font-family: $font;
	color: $primary;
}

.container {
	width: 99vw;
	height: 99vh;
	padding: 0 200px;

	@media (max-width: 1600px) {
		padding: 0 150px;
	}

	@media (max-width: 900px) {
		padding: 0 100px;
	}

	@media (max-width: 650px) {
		padding: 0 30px;
	}
}

.spline-container {
	position: absolute;
	right: 0;
	top: 300px;
}

.container .slick-arrow::before {
	color: $primary;
	font-size: 50px;
}

.awssld__bullets {
	position: absolute;
	bottom: 10px;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 99999;
}

.awssld__content {
	background: white;
}

@media(max-width: 650px) {
	.slick-next {
		right: 20px !important;
	}
}

.typing {
	overflow: hidden;
	/* Ensures the content is not revealed until the animation */
	border-right: 0.15em solid $special;
	/* The typwriter cursor */
	white-space: nowrap;
	/* Keeps the content on a single line */
	margin: 0 auto;
	/* Gives that scrolling effect as the typing happens */
	letter-spacing: 3px;
	/* Adjust as needed */

	white-space: nowrap;
	overflow: hidden;
	max-width: max-content;
	width: 100%;
	font-size: 90px;

	animation: typing 3s steps(60, end), blink-caret 0.75s step-end infinite;

	@media (max-width: 1600px) {
		font-size: 72px;
		animation: typing 2s steps(60, end), blink-caret 0.75s step-end infinite;
	}

	@media (max-width: 900px) {
		font-size: 50px;
	}

	@media (max-width: 650px) {
		font-size: 35px;
		margin-top: 32px;
	}

	@media (max-width: 430px) {
		font-size: 25px;
	}
}

@keyframes appear {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes typing {
	from {
		width: 0;
	}

	to {
		opacity: 1;
	}
}

/* The typewriter cursor effect */
@keyframes blink-caret {

	from,
	to {
		border-color: transparent;
	}

	50% {
		border-color: $special;
	}
}