@import "../../../styles/variables.scss";

.badge {
	width: max-content;
	min-height: 50px;
	height: max-content;
	padding: 10px 15px;
	border-radius: 10px;
	user-select: none;
	display: flex;
	align-items: center;

	&.primary {
		background: $secondary;

		&.link {
			text-decoration: none;
			transition: 0.3s ease;

			&:hover {
				background: darken($secondary, 15%);
			}
		}
	}

	&.success {
		background: rgb(90, 177, 90);
		color: white;
	}


	@media (max-width: 430px) {
		font-size: 12px !important;
	}
}