.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 0;

	.links {
		display: flex;
		gap: 16px;
		align-items: center;

		a {
			text-decoration: none;
		}
	}
}
