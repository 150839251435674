.card {
	display: flex;
	flex-direction: column;
	margin: 92px 0;
	height: 70vh;
	align-items: center;
	justify-content: center;

	img {
		width: 1000px;
		margin-bottom: 92px;
	}

	h2 {
		font-size: 50px;
		text-align: center;
	}

	p {
		font-size: 20px;
		text-align: center;
		margin-bottom: 64px;
		width: 60%;
	}

	.skills {
		display: flex;
		align-items: center;
		gap: 32px;
	}

	.footer {
		display: flex;
		gap: 32px;
		margin-top: 32px;

		img {
			width: 50px;
		}
	}

	.link {
		height: max-content;
		margin-bottom: 64px;

		&:hover {
			text-decoration: none;

		}
	}


	@media (max-width: 1600px) {
		/** **/
	}

	@media (max-width: 650px) {
		h2 {
			font-size: 35px;
		}

		p {
			font-size: 15px;
			margin-bottom: 16px;
		}

		img {
			margin-bottom: 16px;

		}

		.link {
			margin-bottom: 16px;
		}

		.skills {
			div {
				font-size: 15px;
			}
		}

		.footer {
			div {
				max-height: 30px;
				min-height: 30px;
				padding: 0px;
				background: transparent;
				color: black;
			}

			img {
				width: 30px;
			}
		}
	}

	@media (max-width: 430px) {
		h2 {
			font-size: 25px;
		}
	}
}