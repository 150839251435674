@import "../../../styles/variables.scss";

.bio {
	width: 100%;
	position: relative;
	gap: 64px;

	justify-content: space-between;
	display: flex;

	@media (max-width: 1400px) {
		flex-direction: column-reverse;
		align-items: center;
	}

	@media (max-width: 900px) {
		width: 80%;
	}

	@media (max-width: 430px) {
		width: 100%;
		justify-content: center;
		align-items: center;
		padding: 20px;
	}

	img {
		object-fit: cover;
		object-position: top center;

		z-index: 100;

		@media (max-width: 1400px) {
			margin: 0 auto;
			width: 250px;
		}

		@media (max-width: 430px) {
			width: 180px;
		}
	}

	h1 {
		margin-top: 16px;

		overflow: hidden;
		/* Ensures the content is not revealed until the animation */
		border-right: 0.15em solid $special;
		/* The typwriter cursor */
		white-space: nowrap;
		/* Keeps the content on a single line */
		margin: 0 auto;
		/* Gives that scrolling effect as the typing happens */
		letter-spacing: 3px;
		/* Adjust as needed */

		padding-left: 0;
		margin-left: 0;

		font-size: 90px;
		white-space: nowrap;
		overflow: hidden;
		max-width: max-content;
		width: 100%;
		animation: typing 3s steps(60, end), blink-caret 0.75s step-end infinite;

		@media (max-width: 1600px) {
			font-size: 72px;
			animation: typing 2s steps(60, end), blink-caret 0.75s step-end infinite;
		}

		@media (max-width: 900px) {
			font-size: 50px;
		}

		@media (max-width: 650px) {
			font-size: 35px;
			margin-top: 32px;
		}

		@media (max-width: 430px) {
			font-size: 20px;
			width: 100%;
			text-align: center;
		}
	}

	p {
		opacity: 0;

		margin-top: 32px;
		width: 500px;
		font-size: 32px;

		animation-name: appear;
		animation-duration: 0.5s;
		animation-delay: 3.2s;
		animation-fill-mode: forwards;

		@media (max-width: 1600px) {
			width: 300px;
			font-size: 20px;
			animation-delay: 2.2s;
		}

		@media (max-width: 650px) {
			font-size: 15px;
		}

		@media (max-width: 430px) {
			font-size: 12px;
			width: 100%;
			max-width: 100vw;
		}
	}

	.social {
		margin-top: 32px;
		display: flex;
		gap: 16px;

		div {
			cursor: pointer;
		}

		@media (max-width: 650px) {
			flex-direction: column;
		}
	}
}

@keyframes appear {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes typing {
	from {
		width: 0;
	}

	to {
		opacity: 1;
	}
}

/* The typewriter cursor effect */
@keyframes blink-caret {

	from,
	to {
		border-color: transparent;
	}

	50% {
		border-color: $special;
	}
}